class UrlService {
	/**
	 * @param {string} givenUrl
	 * @returns {string}
	 */
	createAbsoluteUrl(givenUrl) {
		if (this._isAbsoluteUrl(givenUrl)) {
			return givenUrl;
		}

		return this.join([this._getBaseHref(), givenUrl]);
	}

	/**
	 * @param {string[]} urlParts
	 * @param {{
	 * trailingSlash?: boolean
	 * }} params
	 * @returns {string}
	 */
	join(urlParts, {trailingSlash = true} = {}) {
		const [baseUrl, ...otherUrlParts] = urlParts,
		      [protocol, rawBaseUrl]      = baseUrl.split('//'),
		      urlBySlashes                = rawBaseUrl.split('/');

		otherUrlParts.forEach((urlPart) => {
			urlBySlashes.push(
				...urlPart.split('/')
			);
		});

		const joinedUrl = urlBySlashes
			      .filter(Boolean)
			      .join('/'),
		      url = `${protocol}//${joinedUrl}`;

		return trailingSlash ? this.addTrailingSlash(url) : url;
	}

	/**
	 * @param {string | undefined} url
	 * @returns {string | undefined}
	 * @todo share the same method between the frontend and the backend
	 * Adds a "/" at the end of the given value if it doesn't end with one
	 */
	addTrailingSlash(url) {
		if (!url) {
			return url;
		}

		const [urlWithoutGet, getParams] = url.split('?');

		if (!urlWithoutGet.endsWith('/')) {
			return `${urlWithoutGet}/${getParams ? `?${getParams}` : ''}`;
		}

		return url;
	}

	/**
	 * @param {string} url
	 * @returns {boolean}
	 * @private
	 */
	_isAbsoluteUrl(url) {
		return url.includes('//');
	}

	/**
	 * @returns {string}
	 * @private
	 */
	_getBaseHref() {
		return $('base').attr('href');
	}
}

export default new UrlService();