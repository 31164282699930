import LazySentry from './LazySentry';
import userCookiesService from './userCookiesService';

const dsn = window.globalParams.SENTRY_DSN;

class SentryService {
	constructor() {
		this.lazySentry = this._createLazySentry();
	}

	/**
	 * @returns {LazySentry}
	 * @private
	 */
	_createLazySentry() {
		const sentryBrowserOptions = {
			dsn,
			'environment'        : process.env.NODE_ENV,
			'enabled'            : !!dsn,
			'autoSessionTracking': false
		};
		
		if (userCookiesService.getId()) {
			sentryBrowserOptions.initialScope = {
				'user': {
					'id': userCookiesService.getId()
				}
			};
		}
		
		return new LazySentry({
			sentryBrowserOptions,
			'earlyErrors': window.earlyErrors
		});
	}

	/**
	 * @returns {LazySentry}
	 */
	getLazySentry() {
		return this.lazySentry;
	}
}

export default new SentryService();