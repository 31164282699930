const MAX_TOKEN_FETCH_RETRY_ATTEMPT = 10;

class CsrfService {
	/**
	 * Gets a new csrf token from the server
	 * @returns {Promise<string | undefined>}
	 */
	fetchCsrf() {
		return new Promise((resolve) => {
			this._fetchCsrfRequest(resolve, 1);
		});
	}

	/**
	 * New csrf token from the server request
	 * Calls itself recursively until a valid token or it gives up
	 * @param {(csrf?: string) => void} resolve
	 * @param {number} 					retryAttempt
	 * @private
	 */
	_fetchCsrfRequest(resolve, retryAttempt) {
		$.get('get-csrf-token/')
			.then((response) => resolve(response && response._csrf))
			.catch((err) => {
				console.error(err);

				if (retryAttempt < MAX_TOKEN_FETCH_RETRY_ATTEMPT) {
					// try again a bit later
					setTimeout(this._fetchCsrfRequest.bind(this, resolve, retryAttempt + 1), 1000);
					// give up and return undefined
				} else {
					resolve();
				}
			});
	}

}

export default new CsrfService();