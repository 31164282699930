import Backdrop from 'bootstrap/js/src/util/backdrop';
import Collapse from 'bootstrap/js/src/collapse';
import Popover from 'bootstrap/js/src/popover';
import Tooltip from 'bootstrap/js/src/tooltip';
import {defineIdleProperty} from 'idlize/defineIdleProperty.mjs';
import {onViewportEnter} from '../helpers/lazyInit';

const MOBILE_BREAK_POINT = 799;

class ContentService {
	constructor() {
		this.popoverEventsInited = false;
		this.isMobileSize = false;

		defineIdleProperty(
			this,
			'isMobileSize',
			() => window.innerWidth < MOBILE_BREAK_POINT
		);
	}

	/**
	 * @param {string} [customClass]
	 * @returns {{template: string}}
	 */
	customPopOverMarkup(customClass = '') {
		return {
			'template': `<div class="popover customPopover ${customClass}" role="tooltip"><div class="arrow"></div><div class="closeBtn"><i class="eventTripsIcon-fas-times"></i></div><div class="popover-content"><h3 class="popover-header"></h3><div class="popover-body"></div></div></div>`
		};
	}

	/**
	 * @param {string} [selector]
	 */
	initToolTips(selector = '[data-bs-toggle="tooltip"]') {
		const tooltips = document.querySelectorAll(selector);

		tooltips.forEach((el) => {
			new Tooltip(el, {
				'html': true
			});
		});
	}
	
	/**
	 * @param {NodeListOf<Element>} toggleElements
	 * @param {HTMLElement}         sourceElement
	 */
	closeOtherOpenedNavBars(toggleElements, sourceElement) {
		[...toggleElements]
			.filter((element) => element !== sourceElement)
			.forEach((element) => {
				const collapseElement = document.querySelector(element.dataset.bsTarget),
				      collapse        = Collapse.getInstance(collapseElement);
				
				if (collapse?._isShown()) {
					element.click();
				}
			});
	}
	
	/**
	 * Adds backdrop support to the given collapse and clicking outside the collapse will close it
	 * @param {HTMLElement} collapseElement
	 */
	enhanceCollapse(collapseElement) {
		collapseElement.addEventListener(
			'show.bs.collapse',
			() => {
				this.#enhanceCollapseOnUsage(collapseElement);
			}
		);
	}
	
	/**
	 * @param {HTMLElement} collapseElement
	 */
	#enhanceCollapseOnUsage(collapseElement) {
		const closeCollapseOnOutsideClick = (ev) => {
			if (!collapseElement.contains(ev.target)) {
				Collapse.getInstance(collapseElement).hide();
				document.body.removeEventListener('click', closeCollapseOnOutsideClick);
			}
		};
		
		let backdrop = new Backdrop({
			'isAnimated' : false,
			'rootElement': collapseElement.parentElement
		});
		
		collapseElement.addEventListener(
			'shown.bs.collapse',
			() => {
				backdrop.show();
				this.#setModalPositionBelowElement(collapseElement);
				
				document.body.addEventListener('click', closeCollapseOnOutsideClick);
			},
			{'once': true}
		);
		collapseElement.addEventListener(
			'hide.bs.collapse',
			() => {
				backdrop.hide();
				backdrop = null;
				document.body.removeEventListener('click', closeCollapseOnOutsideClick);
			},
			{
				'once': true
			}
		);
	}
	
	/**
	 * @param {HTMLElement} element
	 */
	#setModalPositionBelowElement(element) {
		const {height, top}  = element.getBoundingClientRect(),
		      bottomPosition = height + top;
		
		document.querySelector('.modal-backdrop').style.top = `${bottomPosition}px`;
	}
	
	/**
	 * @param {{
	 * selector: string,
	 * params  : Object
	 * }} popoversParams
	 * @returns {IntersectionObserver[]}
	 */
	initPopovers(
		{
			selector = '[data-bs-toggle="popover"]',
			params
		} = {}
	) {
		if ((selector instanceof NodeList) && !Array.isArray(selector)) {
			return [];
		}

		const elements = document.querySelectorAll(selector);

		if (elements.length === 0) {
			return [];
		}

		const finalParams = {...this._getDefaultPopoverParams(), ...params},
		      observers   = [];

		if (elements.length > 0) {
			elements.forEach((element) => {
				const observer = onViewportEnter(
					[element],
					(enteredElement) => {
						const [currentElement] = enteredElement,
						      {customClass}    = currentElement.dataset,
						      customParams     = customClass
												? {...finalParams, ...this.customPopOverMarkup(customClass)}
												: finalParams;
	
						new Popover(currentElement, customParams);
					},
					{
						'threshold': 1
					}
				);
	
				if (observer) {
					observers.push(observer);
				}

				element.addEventListener('click', (ev) => {
					const elementsArray = [...elements].filter((item) => item !== element);

					elementsArray.forEach((el) => {
						Popover.getOrCreateInstance(el).hide();
					});

					if (typeof params !== 'undefined' && !ev.target.dataset.scrollBody) {
						document.body.classList.add('customPopoverOpened');
					}

					ev.stopPropagation();
				});
			});
		}

		if (!this.popoverEventsInited) {
			this.popoverEventsInited = true;
			
			const {body}              = document,
			      shownPopoverHandler = (popoverEvent) => {
				      const clickHandler = (ev) => {
					      const {target} = ev;
					      
					      if (!target.closest('.popover') || target.matches('.close, .closeBtn, .closeBtn i')) {
						      Popover.getOrCreateInstance(popoverEvent.target).hide();
						      body.classList.remove('customPopoverOpened');
					      }
				      };

				      body.addEventListener('click', clickHandler, true);
			      },
			      hiddenPopoverHandler = () => {
				      body.removeEventListener('click', shownPopoverHandler, true);
			      };
			
			body.addEventListener('shown.bs.popover', shownPopoverHandler);
			body.addEventListener('hidden.bs.popover', hiddenPopoverHandler);
		}

		return observers;
	}

	/**
	 * @returns {{container: string, html: boolean, trigger: string}}
	 * @private
	 */
	_getDefaultPopoverParams() {
		return {
			'container': 'body',
			'html'     : true,
			'sanitize' : false,
			'trigger'  : this.isMobileSize ? 'click' : 'hover'
		};
	}
}

export default new ContentService();