import {IdleValue} from 'idlize/IdleValue.mjs';

/**
 * Calls the callback when the first from the given elements enters the viewport
 * @template {Element[]|NodeListOf[]} T
 * @param {T}                        targetElements
 * @param {function(T): void}        cb
 * @param {IntersectionObserverInit} [intersectionOptions]
 * @returns {?IntersectionObserver}
 */
function onViewportEnter(targetElements, cb, intersectionOptions) {
	if (!window.IntersectionObserver) {
		setTimeout(() => cb(targetElements));

		return null;
	}

	const mainObserver = new IntersectionObserver(
		(entries, observer) => {
			const isAnyIntersecting = entries.some(({isIntersecting}) => isIntersecting);

			if (isAnyIntersecting) {
				observer.disconnect();
				cb(targetElements);
			}
		},
		intersectionOptions
	);

	for (const targetElement of targetElements) {
		mainObserver.observe(targetElement);
	}

	return mainObserver;
}

/**
 * Runs the cb instantly when the window is scrolled, or when the browser is idle but at least on the first user scroll
 * @param {() => void} cb
 */
function lazyInitByScroll(cb) {
	if (document.documentElement.scrollTop) {
		cb();
	} else {
		const idleValue = new IdleValue(cb);

		$(window).one('scroll', idleValue.getValue.bind(idleValue));
	}
}

export {
	lazyInitByScroll,
	onViewportEnter
};