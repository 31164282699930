import {TEST_QUERY_KEY} from '#api/services/abtest/abTestConsts';
import abTestService from './abTestService';
import csrfService from './csrfService';
import urlService from './urlService';

class AjaxService {
	/**
	 * @param {!Object} ajaxParams
	 * @param {string} ajaxParams.url
	 * @param {Object} [ajaxParams.data]
	 * @param {{
	 * withCsrf?: boolean
	 * }} [params]
	 * @returns {Promise<!{response, xhr}>}
	 */
	sendPost(ajaxParams, {withCsrf = true} = {}) {
		return new Promise((resolve) => {
			const dataWithCsrfTokenPromise = withCsrf
											? this._getAjaxDataWithCsrfToken(ajaxParams.data)
											: Promise.resolve([ajaxParams.data]);

			dataWithCsrfTokenPromise
				.then(([dataWithCsrfToken, csrf]) => {
					// don't continue with the request without a required csrf token in production
					if (withCsrf && !csrf && process.env.NODE_ENV === 'production') {
						resolve({
							'response': {},
							'xhr'     : {
								'status': 403
							}
						});

						return;
					}

					const finalAjaxParams = this._getFinalAjaxParams(ajaxParams, dataWithCsrfToken);

					$.ajax(finalAjaxParams)
						.always((response, textStatus, xhr) => resolve({response, xhr}))
						.catch(console.error);
				});
		});
	}

	/**
	 * @param {string} 	url
	 * @param {{
	 * withAbTests?: boolean
	 * }} [params]
	 * @returns {JQuery.jqXHR}
	 */
	sendGet(url, {withAbTests = true} = {}) {
		const absoluteUrl = urlService.createAbsoluteUrl(url),
		      urlObject   = withAbTests
							? this._getUrlObjectWithActiveTestVariations(absoluteUrl)
							: new URL(absoluteUrl);

		if (!urlObject.pathname.endsWith('/')) {
			urlObject.pathname = `${urlObject.pathname}/`;
		}

		return $.get(urlObject.toString());
	}

	/**
	 * @param {string} url
	 * @param {string} [activeTestVariations]
	 * @returns {!URL}
	 * @private
	 */
	_getUrlObjectWithActiveTestVariations(url, activeTestVariations = abTestService.getActiveTestVariations()) {
		const urlObject = new URL(url);

		if (activeTestVariations) {
			urlObject.searchParams.append(TEST_QUERY_KEY, activeTestVariations);
		}

		return urlObject;
	}

	/**
	 * @param {!Object} ajaxParams
	 * @param {!Object} data		the final data to be send to the server
	 * @returns {!Object}
	 * @private
	 */
	_getFinalAjaxParams(ajaxParams, data) {
		const {url} = ajaxParams;

		return $.extend(
			{
				'type'       : 'POST',
				'contentType': 'application/json; charset=utf-8'
			},
			ajaxParams,
			{
				'url' : urlService.createAbsoluteUrl(url),
				'data': data || JSON.stringify({})
			}
		);
	}

	/**
	 * @param {!Object} originalData
	 * @returns {Promise<string>}
	 * @private
	 */
	_getAjaxDataWithCsrfToken(originalData) {
		return csrfService.fetchCsrf()
			.then((csrf) => (
				[
					JSON.stringify(
						$.extend(
							{},
							originalData,
							{
								'_csrf': csrf
							}
						)
					),
					csrf
				]
			));
	}
}

export default new AjaxService();