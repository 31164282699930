import $ from 'jquery';
import Modal from 'bootstrap/js/src/modal';
import userService from '../../services/userService';
import userCookiesService from '../../services/userCookiesService';
import contentService from '../../services/contentService';

/**
 * @param {number} count
 */
const updateFavouriteCountsInPage = (count) => {
	$('.favouriteArrangementsCount').html(
		count > 0 ? `<span class="bracket">(</span>${count}<span class="bracket">)</span>` : ''
	);
},
      /**
       * Updates the user specific content in the HTML document
       */
      updateUserSpecificContent = () => {
	      $(document.body).addClass('user-is-logged-in');
	      $('.logged-in-user-email').text(userService.getEmail());
	      contentService.enhanceCollapse(
		      document.getElementById('userNavBar')
	      );
      },
      
      /**
       * Initializes the user login form
       * @param {Object} params
       */
      initLoginForm = (params) => {
	      const loginForm = document.querySelector('.loginForm');
	
	      if (!loginForm) {
		      return;
	      }
	
	      import('./forms/LoginForm')
		      .then(({'default': LoginForm}) => {
			      new LoginForm(loginForm, params);
		      });
      },
      
      /**
       * @param {HTMLElement} clickedOpenerElement
       */
      openLoginModal = (clickedOpenerElement = $('.userNavMenu:visible').get()) => {
	      const $formsContainer    = $('#formsContainer'),
	            modalDialogElement = document.querySelector('#formsContainer .modal-dialog');
	      
	      Modal
		      .getOrCreateInstance($formsContainer.get(0))
		      .toggle();
	      
	      if (window.innerWidth > 799) {
		      const boundingClientRect = clickedOpenerElement.getBoundingClientRect(),
		            posTop             = boundingClientRect.height + boundingClientRect.top,
		            posRight           = boundingClientRect.left + boundingClientRect.width - 350;
		      
		      $formsContainer.addClass('desktop');
		      modalDialogElement.style.top  = `${posTop}px`;
		      modalDialogElement.style.left = `${posRight}px`;
	      } else {
		      $formsContainer.removeClass('desktop');
		      modalDialogElement.style.top  = 'unset';
		      modalDialogElement.style.left = 'unset';
	      }
      },
      
      /**
       * Switches to the user menu for the logged in users
       */
      switchToLoggedInUserMenu = () => {
	      const $userNavMenu  = $('.userNavMenu'),
	            modal         = Modal.getInstance(
		            document.getElementById('formsContainer')
	            );
	      
	      modal?.hide();
	      modal?.dispose();
	      
	      $userNavMenu.find('.eventTripsIcon-fas-times').hide();
	      $userNavMenu.off('click.loginModal');
      },
      
      /**
       * Initializes header related code
       */
      initUserHeader = () => {
	      if (userService.isUserLoggedIn()) {
		      updateUserSpecificContent();
		
		      return;
	      }
	      
	      $('.userNavMenu').find('.eventTripsIcon-fas-times').show();

		  document.getElementById('formsContainer').addEventListener('hidden.bs.modal', () => {
			$('.loginForm').show();
			$('.requestPasswordResetFormContainer, .request-trip-read-access-form, .successfulPasswordResetMessageContainer').hide();
		  });
	      
	      $('.openLoginModal').on(
		      'click.loginModal',
		      (ev) => {
			      openLoginModal(ev.currentTarget);
			      ev.preventDefault();
		      }
	      );
	
	      initLoginForm({
		      'onSuccess': () => {
			      switchToLoggedInUserMenu();
			      userService.updateUserData().then(() => {
				      updateUserSpecificContent();
			      });
		      }
	      });
      },
      
      /**
       * Initializes actions needed on every page for users
       * @returns {Promise}
       */
      initCommonUserActions = async () => {
	      const favouriteArrangementsCount = userCookiesService.getFavouriteArrangementIds().length;
	
	      if (favouriteArrangementsCount) {
		      updateFavouriteCountsInPage(favouriteArrangementsCount);
	      }
	
	      const userViewedArrangementCountElement = document.querySelector('.userViewedArrangementsCount');
	
	      if (userViewedArrangementCountElement) {
		      const arrangementCount = userCookiesService.getRecentlyViewedArrangements().length;
		
		      if (arrangementCount > 0) {
			      userViewedArrangementCountElement.innerHTML = `<span class="bracket">(</span>${arrangementCount}<span class="bracket">)</span>`;
		      }
	      }
	
		  // only run these functions if the user menu is available
		  if ($('.userNavMenu').length) {
			  await userService.updateUserData();
			  initUserHeader();
		  }
      };

export {
	initCommonUserActions,
	openLoginModal,
	switchToLoggedInUserMenu,
	updateFavouriteCountsInPage
};