import LazyLoad from 'vanilla-lazyload';

/**
 * @typedef {import('vanilla-lazyload').ILazyLoadOptions} ILazyLoadOptions
 * @typedef {import('vanilla-lazyload').ILazyLoadInstance} ILazyLoadInstance
 */

const DEFAULT_LAZY_LOAD_PARAMS = {
	      'threshold'    	: 200,
	      'callback_error': (element) => {
		      const $element     = $(element),
		            errorMessage = `error loading ${$element.data('src')}`;

		      console.error(errorMessage);

		      $(document.body).trigger('lazyLoadError', $element);
	      }
},
	/**
	 * @param {ILazyLoadOptions} [params]
	 * @param {!HTMLElement}     [elements]
	 * @returns {!ILazyLoadInstance}
	 */
	lazyLoad = (params, elements) => (
		new LazyLoad(
			params
				? {...DEFAULT_LAZY_LOAD_PARAMS, ...params}
				: DEFAULT_LAZY_LOAD_PARAMS,
			elements
		)
	);

export {lazyLoad};