import ajaxService from './ajaxService';

class UserService {
	/** @type {{ isLoggedIn: boolean, email: string}} */
	rawUser = {};
	
	async updateUserData() {
		this.rawUser = await this.fetchUserDataFromBackend();
	}
	
	async fetchUserDataFromBackend() {
		const {response, xhr} = await ajaxService.sendPost({
			      'url': 'user/get-frontend-data'
		      }),
		      responseObject = typeof xhr === 'object' ? xhr : response;
		
		return responseObject
				.then((responseJSON) => responseJSON || {})
				.catch(() => ({}));
	}
	
	/** @returns {boolean} */
	isUserLoggedIn() {
		return this.rawUser.isLoggedIn || false;
	}
	
	/** @returns {string} */
	getEmail() {
		return this.rawUser.email || '';
	}
	
}

export default new UserService();