module.exports = {
	'WASABI_BASE_URL'           : 'https://wasabi.travelgroep.nl',
	'WASABI_DEFAULT_APPLICATION': 'Frontends',
	'WASABI_TIMEOUT_IN_MS'      : 5000,
	'WASABI_EVENT_NAMES'        : {
		'IMPRESSION': 'IMPRESSION'
	},
	'WASABI_SHORT_USER_ID'        : 'wuid',
	'WASABI_USER_COOKIE'          : 'WASABI_user',
	'TEST_QUERY_KEY'              : 'exp',
	'ACTIVE_VARIATIONS_COOKIE_KEY': 'WASABI_activeVariations',
	'TEST_VARIATIONS_SEPARATOR'   : '|'
};