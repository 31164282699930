import Cookies from 'js-cookie';
import userConsts from '#api/services/user/userConsts';

const {COOKIE_KEYS, DEFAULT_COOKIE_EXPIRATION_IN_DAYS} = userConsts;

class UserCookiesService {
	constructor() {
		/**
		 * @type {!Object}
		 * @private
		 */
		this.cookieValues = this._fetchCookieValues();
	}

	/**
	 * @returns {!Object}
	 * @private
	 */
	_fetchCookieValues() {
		try {
			return JSON.parse(Cookies.get(COOKIE_KEYS.USER_DATA) || '{}');
		} catch {
			// remove the bad data from the cookies
			Cookies.remove(COOKIE_KEYS.USER_DATA);
		}

		return {};
	}
	
	/**
	 * @returns {string}
	 */
	getId() {
		return this.get(COOKIE_KEYS.USER_ID);
	}
	
	/**
	 * @returns {Array}
	 */
	getFavouriteArrangementIds() {
		return this.getArrayCookieData(COOKIE_KEYS.FAVOURITE_ARRANGEMENT_IDS);
	}
	
	/**
	 * @param {number} id
	 */
	storeFavouriteArrangementId(id) {
		this.storeValueInArray(COOKIE_KEYS.FAVOURITE_ARRANGEMENT_IDS, id);
	}
	
	/**
	 * @param {number} id
	 */
	removeFavouriteArrangementId(id) {
		this.storeArrayWithoutValue(COOKIE_KEYS.FAVOURITE_ARRANGEMENT_IDS, id);
	}
	
	/**
	 * @returns {boolean}
	 */
	hasRecentlyViewedItem() {
		return !!this.getRecentlyViewedEvents().length || !!this.getRecentlyViewedArrangements().length;
	}
	
	/**
	 * @returns {Array}
	 */
	getRecentlyViewedEvents() {
		return this.getArrayCookieData(COOKIE_KEYS.RECENTLY_VIEWED_EVENTS);
	}
	
	/**
	 * @param {!Array<!Object>} events
	 */
	storeRecentlyViewedEvents(events) {
		this.storeInUserData(COOKIE_KEYS.RECENTLY_VIEWED_EVENTS, events);
	}
	
	/**
	 * @param {number} id
	 */
	removeViewedEvent(id) {
		this.storeArrayWithoutId(COOKIE_KEYS.RECENTLY_VIEWED_EVENTS, id);
	}
	
	/**
	 * @returns {Array}
	 */
	getRecentlyViewedArrangements() {
		return this.getArrayCookieData(COOKIE_KEYS.RECENTLY_VIEWED_ARRANGEMENTS);
	}
	
	/**
	 * @param {!Array<!Object>} arranges
	 */
	storeRecentlyViewedArrangements(arranges) {
		this.storeInUserData(COOKIE_KEYS.RECENTLY_VIEWED_ARRANGEMENTS, arranges);
	}
	
	/**
	 * @param {number} id
	 */
	removeViewedArrangement(id) {
		this.storeArrayWithoutId(COOKIE_KEYS.RECENTLY_VIEWED_ARRANGEMENTS, id);
	}
	
	/**
	 * @returns {!Array<string>}
	 */
	getLastPackageSearches() {
		return this.getArrayCookieData(COOKIE_KEYS.PACKAGE_SEARCHES);
	}
	
	/**
	 * @param {string} key
	 * @returns {*}
	 */
	get(key) {
		return this.cookieValues[key];
	}

	/**
	 * @param {string} key
	 * @returns {!Array}
	 */
	getArrayCookieData(key) {
		const value = this.cookieValues[key];

		return Array.isArray(value) ? value : [];
	}

	/**
	 * @param {string} 					key
	 * @param {string|number|!Object} 	value
	 */
	storeValueInArray(key, value) {
		let rawValues = this.cookieValues[key];

		if (!Array.isArray(rawValues)) {
			rawValues = [];
		}

		rawValues.push(value);

		this.storeInUserData(key, rawValues);
	}

	storeArrayWithoutId(key, id) {
		let rawValues = this.cookieValues[key];

		if (Array.isArray(rawValues)) {
			const removeFromIndex = rawValues.findIndex((currentValue) => currentValue.id === id);

			rawValues.splice(removeFromIndex, 1);
		} else {
			rawValues = [];
		}

		this.storeInUserData(key, rawValues);
	}

	/**
	 * @param {string} 			   key
	 * @param {string|number|null} value
	 */
	storeArrayWithoutValue(key, value) {
		let rawValues = this.cookieValues[key];

		if (Array.isArray(rawValues)) {
			rawValues.splice(rawValues.indexOf(value), 1);
		} else {
			rawValues = [];
		}

		this.storeInUserData(key, rawValues);
	}

	/**
	 * @param {string} 	key
	 * @param {*} 		value
	 */
	storeInUserData(key, value) {
		this._updateCookieData(key, value);
		Cookies.set(
			COOKIE_KEYS.USER_DATA,
			JSON.stringify(this.cookieValues),
			{
				'expires' : DEFAULT_COOKIE_EXPIRATION_IN_DAYS,
				'sameSite': 'Strict'
			}
		);
	}

	/**
	 * @param {string} 	key
	 * @param {*} 		value
	 * @private
	 */
	_updateCookieData(key, value) {
		const removeKey = !value || (Array.isArray(value) && !value.length);

		if (removeKey) {
			delete this.cookieValues[key];
		} else {
			this.cookieValues[key] = value;
		}
	}

}

export default new UserCookiesService();