import $ from 'jquery';

/**
 * @param {number | string} price
 * @param {{
 * withPP: boolean
 * }} [params]
 * @returns {string}
 */
function formatPrice(price, {withPP = false} = {}) {
	/* eslint-disable-next-line no-undef */ // todo booking remove dictionary usages
	const formattedPrice = dictionary.price_with_currency.replace(
		':price:',
		Math.ceil(price)
	);
	
	return withPP
			? `${formattedPrice} ${dictionary.pricePerPerson}` /* eslint-disable-line no-undef */ // todo booking remove dictionary usages
			: formattedPrice;
}

function getDynamicValue(str, params) {
	let result = str;

	$.each(params, (key, value) => {
		result = result.replace(new RegExp(`%${key}%`), value);
	});

	return result;
}

function getDevType() {
	const innerWidth 	= $(window).innerWidth(),
		  maxMobile 	= 600,
		  maxTablet 	= 1024;

	if (innerWidth <= maxMobile) {
		return 'mobile';
	}

	if (innerWidth > maxMobile && innerWidth < maxTablet) {
		return 'tablet';
	}

	return 'desktop';
}

/**
 * Returns the same values as jQuery's offset method but faster
 * @param {Element} element
 * @returns {{top: number, left: number}}
 */
function getOffset(element) {
	const rect = element.getBoundingClientRect();

	return {
		get 'top'() {
			return rect.top + document.documentElement.scrollTop;
		},
		get 'left'() {
			return rect.left + document.documentElement.scrollLeft;
		}
	};
}

function unique(arr) {
	return arr.filter((value, index) => arr.indexOf(value) === index);
}

export {
	formatPrice,
	getDynamicValue,
	getDevType,
	getOffset,
	unique
};