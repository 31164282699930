import Cookies from 'js-cookie';
import Modal from 'bootstrap/js/src/modal';
import {isAfter, isBefore} from 'date-fns';
import Newsletter from './Newsletter';

const COOKIE_KEY       = 'prizegame2021',
      MODAL_OPEN_DELAY = 18_000;

class PrizeGameModal {
	constructor() {
		this.modal = 'prizeGame2021Modal';
		this.modalElement = document.getElementById(this.modal);
		
		this._init();
	}
	
	/** @private */
	_init() {
		this._handleNewsletterSubscribers();
		
		if (!this._shouldShowModal()) {
			return;
		}
	
		this._initEvents();
		
		setTimeout(this._openModal.bind(this), MODAL_OPEN_DELAY);
	}
	
	/**
	 * Never show the popup when the user came from a newsletter
	 * @private
	 */
	_handleNewsletterSubscribers() {
		if (this._didTheUserCameFromNewsletter()) {
			this._setSkipModalCookie();
		}
	}
	
	/** @private */
	_initEvents() {
		if (this.modalElement) {
			this.modalElement.addEventListener('shown.bs.modal', () => {
				this.newsletter = this._createNewsletterApi();
			}, {'once': true});

			this.modalElement.addEventListener('hide.bs.modal', () => {
				this._setSkipModalCookie();
			}, {'once': true});
		}
	}
	
	/**
	 * @returns {Newsletter}
	 * @private
	 */
	_createNewsletterApi() {
		return new Newsletter(
			this.modalElement.querySelector('form'),
			{
				'newsletterTargetUrl'  : '/newsletter/prize_game_2021/sign_up/',
				'processSignUpResponse': this._processSignUpResponse.bind(this)
			}
		);
	}
	
	/** @private */
	_openModal() {
		document.body.classList.add('autoOverflow');

		if (this.modalElement) {
			Modal
				.getOrCreateInstance(this.modalElement)
				.show();

			this.modalElement.classList.add('showTransform');
		}
	}
	
	/**
	 * @param {Object} res
	 * @private
	 */
	_processSignUpResponse(res) {
		if (this.modalElement) {
			this.modalElement.addEventListener('hide.bs.modal', () => {
				this.newsletter.processSignUpResponse(res);
				this._setSkipModalCookie();
			}, {'once': true});

			Modal
				.getOrCreateInstance(this.modalElement)
				.hide();
		}
	}
	
	/**
	 * @returns {boolean}
	 * @private
	 */
	_didTheUserCameFromNewsletter() {
		return new URLSearchParams(location.search).get('utm_medium') === 'email';
	}
	
	/** @private */
	_setSkipModalCookie() {
		Cookies.set(
			COOKIE_KEY,
			1,
			{
				'expires': 365
			}
		);
	}
	
	/**
	 * @returns {boolean}
	 * @private
	 * @todo BF: remove after
	 */
	_isBlackFridayActive() {
		const now = new Date();
		
		return isAfter(now, new Date('2022-11-14')) && isBefore(now, new Date('2022-12-02'));
	}
	
	/**
	 * @returns {boolean}
	 * @private
	 */
	_shouldShowModal() {
		return !!this.modalElement && !Cookies.get(COOKIE_KEY) && !this._isBlackFridayActive();
	}
}

export default PrizeGameModal;