import $ from 'jquery';
import Cookies from 'js-cookie';
import {getDevType} from '@utils/commonUtils';
import {
	WASABI_SHORT_USER_ID, WASABI_USER_COOKIE, ACTIVE_VARIATIONS_COOKIE_KEY, TEST_VARIATIONS_SEPARATOR
} from '#api/services/abtest/abTestConsts';

class AbTestService {
	constructor() {
		/**
		 * @type {string|undefined}
		 * @private
		 */
		this.userId = Cookies.get(WASABI_USER_COOKIE);
	}

	init() {
		this._sendActiveVariationsImpressions();

		if (this.hasUserId()) {
			this.addWuIdOnBookingLinkClick();
		}
	}

	/**
	 * @param {!jQuery} links
	 */
	addWuIdOnBookingLinkClick(links = $('.link-to-pbe')) {
		links.on('mousedown', (ev) => {
			const element = ev.currentTarget;

			element.href = this._getLinkWithWuid(element.href);
		});
	}

	/**
	 * @param {string} testNamesList
	 */
	sendImpression(testNamesList) {
		if (!this.userId) {
			return;
		}

		navigator.sendBeacon(
			`abtests/impression/${testNamesList}/${this.userId}/`
		);
	}

	/**
	 * @param {string} testName
	 * @param {string} event
	 */
	sendEvent(testName, event) {
		if (!this.userId) {
			return;
		}

		navigator.sendBeacon(
			`abtests/event/${testName}/${event}/${this.userId}/`
		);

		navigator.sendBeacon(
			`abtests/event/${testName}/${event}_${getDevType()}/${this.userId}/`
		);
	}
	/**
	 * @param {string} href
	 * @param {string} [wuId]
	 * @returns {string}
	 * @private
	 */
	_getLinkWithWuid(href, wuId = this.userId) {
		const [baseHref, getParams] = href.split('?'),
			  params = new URLSearchParams(getParams);

		params.set(WASABI_SHORT_USER_ID, wuId);

		return `${baseHref}?${params.toString()}`;
	}

	_sendActiveVariationsImpressions() {
		const possibleTestVariationsData = this.getPossibleTestVariationsData();

		if (possibleTestVariationsData) {
			const testNamesNeedImpressions = this.getActiveTestVariations()
											.split(TEST_VARIATIONS_SEPARATOR)
											.map((variationName) => possibleTestVariationsData[variationName])
											.filter((variationData) => variationData && variationData.impressionOnPageLoad)
											.map((variationData) => variationData.test);

			if (testNamesNeedImpressions.length) {
				this.sendImpression(testNamesNeedImpressions.join(TEST_VARIATIONS_SEPARATOR));
			}
		}
	}

	/**
	 * @param {!Array<string>} variationNames
	 * @returns {boolean}
	 */
	isAnyVariationActive(variationNames) {
		return variationNames.some(this.isVariationActive.bind(this));
	}

	/**
	 * @param {string} variationName
	 * @returns {boolean}
	 */
	isVariationActive(variationName) {
		return this.getActiveTestVariations()
				.split(TEST_VARIATIONS_SEPARATOR)
				.includes(variationName);
	}

	/**
	 * Returns only the active test variations from cookies which are possible on the current page (given from backend)
	 * @returns {string}
	 */
	getActiveTestVariations() {
		const cookieTestVariations 		 = this._getTestVariationsFromCookies(),
			  possibleTestVariationsData = this.getPossibleTestVariationsData();

		if (!cookieTestVariations || !possibleTestVariationsData) {
			return '';
		}

		return cookieTestVariations
				.split(TEST_VARIATIONS_SEPARATOR)
				.filter((variation) => !!possibleTestVariationsData[variation])
				.join(TEST_VARIATIONS_SEPARATOR);
	}

	/**
	 * @returns {string}
	 * @private
	 */
	_getTestVariationsFromCookies() {
		return Cookies.get(ACTIVE_VARIATIONS_COOKIE_KEY) || '';
	}

	/**
	 * @returns {!Object|undefined} this data came from the backend
	 */
	getPossibleTestVariationsData() {
		return window.globalParams.possibleTestVariations;
	}

	/**
	 * @returns {boolean}
	 */
	hasUserId() {
		return !!this.userId;
	}

	/**
	 * @returns {string|undefined}
	 */
	getUserId() {
		return this.userId;
	}

	/**
	 * @returns {string}
	 */
	getWasabiShortUserId() {
		return WASABI_SHORT_USER_ID;
	}
}

export default new AbTestService();