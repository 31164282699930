/**
 * @typedef {import('@sentry/browser')}                                       Sentry
 * @typedef {import('@sentry/browser/build/npm/types/client').BrowserOptions} BrowserOptions
 */

/**
 * @class LazySentry
 */
class LazySentry {
	/**
	 * @param {LazySentryOptions} [options]
	 */
	constructor(options = {}) {
		/**
		 * @type {LazySentryOptions}
		 * @private
		 */
		this.options  = options;

		/**
		 * @type {?Sentry}
		 * @private
		 */
		this.Sentry = null;

		/**
		 * @type {boolean}
		 * @private
		 */
		this.isInitialized = false;

		/**
		 * @type {Function}
		 * @private
		 */
		this.errorListener = this.captureError.bind(this);

		/**
		 * @type {Error[]}
		 * @private
		 */
		this.earlyErrors = Array.isArray(options.earlyErrors) ? options.earlyErrors : [];

		window.addEventListener('error', this.errorListener);
		window.addEventListener('unhandledrejection', this.errorListener);

		if (this.earlyErrors.length) {
			this._debug('Init because of earlyErrors');
			this._loadSentry();
		}
	}

	/**
	 * @returns {?Sentry}
	 */
	getSentry() {
		return this.Sentry;
	}

	/**
	 * Sends Error to Sentry
	 * @param {!Error} err
	 */
	captureError(err) {
		this._debug('CaptureError method called', err);

		if (this.Sentry) {
			this.Sentry.captureException(err);
		} else {
			this.earlyErrors.push(err);
			this._loadSentry();
		}
	}

	/**
	 * @returns {Promise}
	 * @private
	 */
	_loadSentry() {
		return this._importSentry()
				.then((Sentry) => {
					if (this.isInitialized) {
						this._debug('Sentry loaded but it was already inited');
					} else {
						this.Sentry = /** @type {!Sentry} */ (Sentry);
						this._initSentry();
					}
				})
				.catch(console.error);
	}

	/**
	 * @returns {Promise<Sentry>}
	 * @private
	 */
	_importSentry() {
		const {customImport} = this.options;

		if (customImport) {
			return customImport();
		}
		
		return import(/* webpackChunkName: "sentry" */ '@sentry/browser');
	}

	/**
	 * @private
	 */
	_initSentry() {
		const {beforeInit, afterInit} = this.options;

		this._removeGlobalErrorListeners(); // let Sentry handle global errors starting now

		if (typeof beforeInit === 'function') {
			beforeInit(this);
		}
		
		this.Sentry.init(this.options.sentryBrowserOptions);
		
		if (typeof afterInit === 'function') {
			afterInit(this);
		}

		this.isInitialized = true;
		this._debug('Initialized');
		this._afterSentryInit();
	}

	/**
	 * @private
	 */
	_afterSentryInit() {
		this.earlyErrors.forEach((err) => {
			const parsedErr = err.error || err.message || err.originalError || err;

			this.Sentry.captureException(parsedErr);
		});
		this.earlyErrors = [];
	}

	/**
	 * @private
	 */
	_removeGlobalErrorListeners() {
		window.removeEventListener('error', this.errorListener);
		window.removeEventListener('unhandledrejection', this.errorListener);
	}

	/**
	 * @param {...any} message
	 * @private
	 */
	_debug(message) {
		if (!this.options.debug) {
			return;
		}

		console.log('[Sentry]', message);
	}

}

export default LazySentry;

/**
 * @typedef {!Object} LazySentryOptions
 * @property {BrowserOptions}              [sentryBrowserOptions]
 * @property {boolean}                     [debug]
 * @property {Error[]}                     [earlyErrors]
 * @property {string}                      [sentryPath]
 * @property {function(): Promise<Sentry>} [customImport]
 * @property {function(lazySentry): void}  [beforeInit]
 * @property {function(lazySentry): void}  [afterInit]
 */