import Carousel from 'bootstrap/js/src/carousel';

class MainBenefitsBanner {
	constructor() {
		this.mainBenefitsBanner = document.querySelector('#mainBenefitsBanner');
		this.duration = 4000;
		this.mobileBreakpoint = 768;
		this.carouselInstance = null;
		this.init();
	}

	init() {
		this.initMainBenefitsBanner();
	}

	initMainBenefitsBanner() {
		if (this.mainBenefitsBanner) {
			const controllMainBenefitsBanner = () => {
				if (window.innerWidth < this.mobileBreakpoint) {
					if (!this.carouselInstance) {
						const moneyBackGuaranteeBenefit = this.mainBenefitsBanner.querySelector('.moneyBackGuaranteeBenefit');
						
						// todo: remove this hack after moneyBackGuaranteeIcon will be removed from the desktop view too
						moneyBackGuaranteeBenefit?.remove();
						
						this.carouselInstance = Carousel.getOrCreateInstance(this.mainBenefitsBanner, {
							'interval': this.duration
						});
					}

					this.mainBenefitsBanner.classList.add('carousel');
					this.carouselInstance.cycle();
				} else {
					this.mainBenefitsBanner.classList.remove('carousel');

					const carouselInstance = Carousel.getInstance(this.mainBenefitsBanner);

					if (carouselInstance) {
						carouselInstance.pause();
					}
				}
			};

			controllMainBenefitsBanner();

			window.addEventListener('resize', () => {
				controllMainBenefitsBanner();
			});
		}
	}
}

export default MainBenefitsBanner;