class ActiveCampaignNewsletters {
	/**
	 * This is where the Active Campaign script will render it's newsletter form
	 * @type {HTMLElement}
	 */
	#hiddenContainer;
	
	constructor(hiddenContainer) {
		this.#hiddenContainer = hiddenContainer;
		this.#init();
	}
	
	#init() {
		if (this.#hiddenContainer.children.length) {
			this.#renderNewsletters();
		} else {
			const observer = new MutationObserver(() => {
				this.#renderNewsletters();
				observer.disconnect();
			});

			observer.observe(
				this.#hiddenContainer,
				{
					'subtree'  : false,
					'childList': true
				}
			);
		}
	}
	
	#renderNewsletters() {
		const clone = this.#hiddenContainer.cloneNode(true);
		
		clone.classList.remove('hidden-element');
		
		for (const newsletterForm of document.querySelectorAll('.active-campaign-newsletter-holder')) {
			newsletterForm.append(clone.cloneNode(true));
		}
	}
}

export default ActiveCampaignNewsletters;