import Modal from 'bootstrap/js/src/modal';
import ajaxService from '../../services/ajaxService';

/** @type {Partial<NewsletterParams>} */
const DEFAULT_PARAMS = {
	'newsletterTargetUrl': '/newsletter/sign_up/'
};

class Newsletter {
	/**
	 * @param {HTMLFormElement}  form
	 * @param {NewsletterParams} params
	 */
	constructor(form, params = {}) {
		/**
		 * @type {HTMLFormElement}
		 * @private
		 */
		this.form = form;
		
		/**
		 * @type {NewsletterParams}
		 * @private
		 */
		this.params = {...DEFAULT_PARAMS, ...params};
		
		/**
		 * Whether submitting the form is in progress
		 * @type {boolean}
		 * @private
		 */
		this.isSubmitting = false;

		this._init();
	}

	/**
	 * @private
	 */
	_init() {
		this.form.addEventListener('submit', (ev) => {
			this._signUp();
			ev.preventDefault();
		});
	}

	/**
	 * @private
	 */
	_signUp() {
		const formData = new FormData(this.form),
		      email    = formData.get('email');

		if (!email) {
			const newslettermodalEmptyEmail = document.getElementById('newslettermodal_empty_email');

			if (newslettermodalEmptyEmail) {
				Modal
					.getOrCreateInstance(newslettermodalEmptyEmail)
					.show();
			}

			return;
		}

		if (this.isSubmitting) {
			return;
		}

		this.isSubmitting = true;

		ajaxService.sendPost({
			'url' : this.params.newsletterTargetUrl,
			'data': {
				email
			}
		})
			.then((res) => {
				this.params.processSignUpResponse
					? this.params.processSignUpResponse(res)
					: this.processSignUpResponse(res);
				
				this.isSubmitting = false;
			});
	}
	
	/**
	 * @param {{
	 * response: Object,
	 * xhr     : Object | string
	 * }} res
	 */
	processSignUpResponse({response, xhr}) {
		let modal;
		
		const status = xhr.status || response.status,
		      {
				  'responseJSON': {reason} = {}
			  } = response;
		
		if (status === 201) {
			modal = 'newslettermodal_success_signup';
			this.form.querySelector('input[name="email"]').value = '';
			this._afterSubscription();
		} else if (reason === 'invalid_email') {
			modal = 'newslettermodal_invalid_email_format';
		} else if (reason === 'already_subscribed') {
			modal = 'newslettermodal_already_subscribed';
		} else {
			modal = 'newslettermodal_unknow_error';
		}
		
		const modalElement = document.getElementById(modal);

		if (modalElement) {
			Modal
				.getOrCreateInstance(modalElement)
				.show();
		}
	}
	
	/**
	 * @private
	 */
	_afterSubscription() {
		const modal = this._getModalElement();

		if (modal) {
			Modal
				.getOrCreateInstance(modal)
				.hide();
		}
	}

	/**
	 * @returns {!HTMLElement}
	 * @private
	 */
	_getModalElement() {
		return this.form.closest('.modal');
	}

}

export default Newsletter;

/**
 * @typedef {{
 * newsletterTargetUrl?: string,
 * processSignUpResponse?: function(!Object): void
 * }} NewsletterParams
 */